<!--
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2023-06-12 14:07:01
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2024-03-25 19:56:12
 * @Description: 
-->
<template>
	<div class="container">
		<a-form-model ref="form" :model="form" :label-col="{span: 4}" :wrapper-col="{span: 14}">
			<a-form-model-item label="商家微官网转发标题设置：" prop="mini_shop_index_share_title">
				<a-input placeholder="请输入商家微官网转发标题" v-model="form.mini_shop_index_share_title" />
				<template #help>
					<div class="flex text-sm form-item-mark">
						<div class="text-theme-red mr-2">注:</div>
						<div>
							<p>
								支持的变量：<span class="text-theme-red">#餐厅名称# </span>
								<span class="text-theme-red">#销售经理姓名# </span>
								<span class="text-theme-red">#销售经理职位# </span>
								使用时将带双#号的变量输入至标题即可
							</p>
						</div>
					</div>
				</template>
			</a-form-model-item>
			<a-form-model-item label="邀请函转发标题设置：" prop="mini_shop_invitation_title">
				<a-input placeholder="请输入邀请函转发标题" v-model="form.mini_shop_invitation_title" />
				<template #help>
					<div class="flex text-sm form-item-mark">
						<div class="text-theme-red mr-2">注:</div>
						<div>
							<p>
								支持的变量：<span class="text-theme-red">#预订人姓名# </span>
								<span class="text-theme-red">#预订人性别# </span>
								使用时将带双#号的变量输入至标题即可
							</p>
						</div>
					</div>
				</template>
			</a-form-model-item>
			<!-- <a-form-model-item
				label="餐厅主页顶部背景图"
				prop="mini_shop_index_top_background_image"
				ref="mini_shop_index_top_background_image">
				<img-uploader
					v-model="form.mini_shop_index_top_background_image"
					@success="
						$refs.mini_shop_index_top_background_image.onFieldBlur()
					"
					@remove="
						$refs.mini_shop_index_top_background_image.onFieldBlur()
					" />
				<template #help>
					<div class="flex text-sm mb-4 form-item-mark">
						<div class="text-theme-red mr-2">注:</div>
						<div>
							<p>1、图片格式必须为 jpg / png</p>
							<p>2、建议尺寸：宽750px，高1100px</p>
						</div>
					</div>
				</template>
			</a-form-model-item> -->
			<!-- <a-form-model-item
				label="邀请函顶部背景图"
				prop="mini_shop_invitation_top_background_image"
				ref="mini_shop_invitation_top_background_image">
				<img-uploader
					v-model="form.mini_shop_invitation_top_background_image"
					@success="
						$refs.mini_shop_invitation_top_background_image.onFieldBlur()
					"
					@remove="
						$refs.mini_shop_invitation_top_background_image.onFieldBlur()
					" />
				<template #help>
					<div class="flex text-sm mb-4 form-item-mark">
						<div class="text-theme-red mr-2">注:</div>
						<div>
							<p>1、图片格式必须为 jpg / png</p>
							<p>2、建议尺寸：宽750px，高1100px</p>
						</div>
					</div>
				</template>
			</a-form-model-item> -->
			<a-form-model-item label="邀请函公告设置：" prop="invitation_tip">
				<a-input placeholder="请输入邀请函公告" v-model="form.invitation_tip" />
				<template #help>
					<div class="flex text-sm form-item-mark">
						<div class="text-theme-red mr-2">注:</div>
						<div>
							<p>输入保存后此公告将展示在邀请函顶部位置，若字数过多，会滚动显示</p>
						</div>
					</div>
				</template>
			</a-form-model-item>
			<a-form-model-item :wrapper-col="{span: 2, offset: 22}">
				<a-button block type="primary" size="large" @click="showModal">保存</a-button>
			</a-form-model-item>
		</a-form-model>
	</div>
</template>

<script>
import {Modal} from 'ant-design-vue'
import _ from 'lodash'
import {setConfig, getConfig} from '@/api/personalization'
import ImgUploader from '@/components/imgUploader'

export default {
	name: 'PersonalSetting',
	components: {ImgUploader},
	data() {
		return {
			form: {
				mini_shop_index_share_title: '', // 餐厅主页转发标题
				mini_shop_index_top_background_image: '', // 餐厅主页顶部背景图
				mini_shop_invitation_title: '', // 邀请函转发标题
				mini_shop_invitation_top_background_image: '' // 邀请函顶部背景图
			}
		}
	},
	created() {
		this.$nextTick(() => {
			this.fetchSetting()
		})
	},
	methods: {
		/**
		 * @description: 获取配置信息
		 * @return {*}
		 */
		async fetchSetting() {
			this.$store.dispatch('loading/startContentLoading')
			const {code, data = {}} = await getConfig()
			if (code === 0) {
				this.form = {...this.form, ...data.userSettings}
			}
			this.$store.dispatch('loading/endContentLoading')
		},
		/**
		 * @description: 保存弹窗
		 * @return {*}
		 */
		showModal() {
			Modal.confirm({
				title: '提示',
				content: '确认保存配置吗？',
				onOk: async () => {
					await this.submitSetting()
				}
			})
		},
		/**
		 * @description: 保存表单内容
		 * @return {*}
		 */
		async submitSetting() {
			this.$message.loading({
				content: '正在保存中...',
				key: 'save',
				duration: 0
			})
			const form = _.cloneDeep(this.form)
			// 转换字段
			const {code} = await setConfig({
				config_name: 'userSettings',
				config_value: form
			})
			if (code === 0) {
				this.$message.success({content: '保存成功！', key: 'save'})
				this.fetchSetting()
			}
		}
	}
}
</script>
<style scoped lang="less">
.container {
	padding: 25px 20px 1px 50px;
	margin: 0;
	max-width: 100% !important;
	background-color: #fff;
	border-radius: 4px;
	box-sizing: border-box;
	.form-item-mark {
		padding-bottom: 10px;
		margin-top: 6px;
	}
}
</style>
